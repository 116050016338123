<template>
	<div>
		<div class="class_nav">
			<div class="nav_main">
				<div class="addsingle">
					<div class="global-search-single">
						<div class="single-left">
							<el-button type="primary" @click="addSuport()" v-if="hasPerm(['goods.label.store'])">+添加标签</el-button>
							<el-button type="primary" @click="addImportManage">批量上传</el-button>
						</div>
						<div class="single-right">
							<div class="search-item">
								<label class="search-label">标签名称:</label>
								<el-input type="text" placeholder="请输入内容" v-model="note_name" ref="supportsearchtext" />
							</div>
							<el-button type="primary" @click="supportsearch()">搜索</el-button>
						</div>
					</div>
				</div>
				<div class="classifyTable">
					<div class="table_head addtable_headed clearfloat" style="padding-left: 0;">
						<el-select v-model="value" placeholder="批量设置" @change="batchDelete">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<el-table :data="tableData" style="width: 100%" ref="multipleTable" @selection-change="singletable">
						<el-table-column type="selection" prop="id" label="ID">
						</el-table-column>
						<el-table-column prop="id" label="ID"></el-table-column>
						<el-table-column label="标签名称(可修改)" prop="name">
						</el-table-column>
						<el-table-column label="加入商品标签" class-name="table_memo" align="center">
							<template slot-scope="scope">
								<div class="table_memoed">
									<p @click="note_pad(scope)" :class="scope.row.is_show ? 'active' : ''">
										{{ scope.row.is_show ? "是" : "否" }}
									</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="添加时间" prop="created_at">
						</el-table-column>
						<el-table-column label="操作" fixed="right" width="200">
							<template slot-scope="scope">
								<div class="caozuo">
									<a @click="suportedit(scope.row.id)" v-if="hasPerm(['goods.label.update'])">编辑</a>
									<a @click="suppordelect(scope.row.id)" v-if="hasPerm(['goods.label.delete'])">删除</a>
								</div>
							</template>
						</el-table-column>
						<template slot="empty">
							<div class="empty_data">
								<p>暂无数据</p>
							</div>
						</template>
					</el-table>
				</div>
				<div class="table_foot">
					<div class="foot_left"></div>
					<div class="main_pages">
						<el-pagination @size-change="handleSizeChange" hide-on-single-page
							@current-change="handleCurrentChange" :current-page.sync="currentPage"
							:page-sizes="[10, 20, 30, 40]" :page-size="eachPage" layout="sizes, prev, pager, next, jumper"
							:pager-count="5" :total="total_entry">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :title="supportext" :visible.sync="addtionSuport" width="30%">
			<div class="add_tag">
				<el-form :rules="tagRules" ref="tagForm" :model="tagForm" label-width="160px">
					<el-form-item label="标签名称" prop="name">
						<div class="inline-block biaoqian">
							<el-input v-model="tagForm.name" maxlength="10"></el-input>
						</div>
						<div class="tagTips" v-if="sift == 0">注：可用英文,号隔开批量上传</div>
					</el-form-item>
					<el-form-item label="加入商品标签" prop="is_add">
						<el-radio-group v-model="tagForm.is_add">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
						<div class="tagTips">
							注：加入商品标签可在（添加/编辑）商品中显示，前台可根据标签搜索该商品。
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addtionSuport = false">取 消</el-button>
				<el-button type="primary" @click="suportsubmit('tagForm')" v-if="hasPerm(['goods.label.store'])">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 导入分组 -->
		<diy-import-more ref="diyImportMore" dialogTitle="导入标签" dialogImportUrl="/label/import"
			dialogTemplateUrl="label.import.template" @dialogImportCallback="importCallback"></diy-import-more>
	</div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          label: "批量删除",
          value: "delete",
        },
      ],
      value: "",
      tableData: [],
      note_name: "",
      currentPage: 1,
      eachPage: 10,
      total_entry: 0,
      supportext: "",
      sift: 0,
      multipleSelection: [],
      addtionSuport: false, //控制添加标签的变量
      supportdelete: [],
      puturlid: "", //编辑当前id
      // 添加标签
      tagRules: {
        name: {
          required: true,
          message: "请输入标签名称",
          trigger: "blur",
        },
        is_add: {
          required: true,
          message: "请选择是否加入商品标签",
          trigger: "blur",
        },
      },
      tagForm: {
        name: "", //标签名称
        is_add: 1, //加入商品标签
      },
      batchSuport: false, //批量上传的变量
      leadform: "", //导入模板的地址
    };
  },
  mounted() {
    this.supportlistapi();
  },
  methods: {
    singletable(val) {
      this.multipleSelection = val;
    },
    note_pad(value) {
      let index = value.$index;
      this.tableData[index].is_show = !this.tableData[index].is_show;
      let item = value.row;
      let data = {
        name: item.name,
        is_show: item.is_show?1:0,
      };
      this.$put(this.$apis.supportedit + item.id, data)
        .then((res) => {
          // console.log(res)
          if (res.code == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "修改成功",
              onClose: () => {},
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 打开导入弹窗
    addImportManage() {
      this.$refs.diyImportMore.importMore = true;
    },
    // 导入成功的回调
    importCallback() {
      // 成功导入，刷新列表
      this.supportlistapi();
    },
    supportlistapi() {
      let data = {
        search: [
          {
            key: "name",
            value: this.note_name,
            op: "search",
          },
        ],
        order: [
          {
            key: "id",
            value: "desc",
          },
        ],
        page: this.currentPage,
        limit: this.eachPage,
      };
      this.$get(this.$apis.supportList, data)
        .then((res) => {
          // console.log(res)
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.total_entry = res.data.total;
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 添加标签
    addSuport() {
      this.addtionSuport = true;
      this.supportext = "添加标签";
      this.sift = 0;
    },
    // 编辑标签
    suportedit(id) {
      this.addtionSuport = true;
      this.supportext = "编辑标签";
      this.sift = 1;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == id) {
          this.tagForm.name = this.tableData[i].name;
          this.tagForm.is_add = this.tableData[i].is_show;
        }
      }
      this.puturlid = id;
    },
    // 批量删除
    batchDelete(val) {
      if (!this.multipleSelection.length) {
        this.$message({
          showClose: true,
          message: "请选中要删除的标签",
          type: "warning",
          offset: 200,
          duration: 1000,
        });
        this.value = "批量设置";
        return false;
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.supportdelete.push(this.multipleSelection[i].id);
      }
      // console.log(this.supportdelete)
      let data = {
        id: this.supportdelete,
      };
      this.$delete(this.$apis.supportAlldelect, data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "删除成功",
              onClose: () => {
                this.supportlistapi();
                this.supportdelete = [];
                this.value = "批量设置";
              },
            });
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    // 单个删除标签
    suppordelect(id) {
      if (!this.multipleSelection.length) {
        this.$message({
          showClose: true,
          message: "请选中当前标签",
          type: "warning",
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      let delct = false;
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].id == id) {
          delct = true;
        }
      }
      if (delct) {
        this.supportdelete.push(id);
        let data = {
          id: this.supportdelete,
        };
        // console.log(data)
        this.$delete(this.$apis.supportAlldelect, data)
          .then((res) => {
            // console.log(res)
            if (res.code == 200) {
              this.$message({
                type: "success",
                duration: 1500,
                message: "删除成功",
                onClose: () => {
                  this.supportlistapi();
                  this.supportdelete = [];
                },
              });
            } else {
              this.common.message(this, res.message);
            }
          })
          .catch((err) => {
            // console.log(err)
            this.common.message(this, err.message);
          });
      }
    },
    // 新增标签
    suportsubmit(formName) {
      if (this.sift == 0) {
        // 添加标签
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {
              name: this.tagForm.name,
              is_show: this.tagForm.is_add,
            };
            this.$post(this.$apis.addSupport, data)
              .then((res) => {
                // console.log(res)
                if (res.code == 200) {
                  this.addtionSuport = false;
                  this.$message({
                    type: "success",
                    duration: 1500,
                    message: "添加成功",
                    onClose: () => {
                      this.supportlistapi();
                      this.tagForm.name = "";
                      this.tagForm.is_add = 1;
                    },
                  });
                } else {
                  this.common.message(this, res.message);
                }
              })
              .catch((err) => {
                // console.log(err)
                this.common.message(this, err.message);
              });
          } else {
            return false;
          }
        });
      } else {
        // 编辑标签
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {
              name: this.tagForm.name,
              is_show: this.tagForm.is_add,
            };
            this.$put(this.$apis.supportedit + this.puturlid, data)
              .then((res) => {
                // console.log(res)
                if (res.code == 200) {
                  this.addtionSuport = false;
                  this.$message({
                    type: "success",
                    duration: 1500,
                    message: "修改成功",
                    onClose: () => {
                      this.supportlistapi();
                      this.tagForm.name = "";
                      this.tagForm.is_add = 1;
                    },
                  });
                } else {
                  this.common.message(this, res.message);
                }
              })
              .catch((err) => {
                // console.log(err)
                this.common.message(this, err.message);
              });
          } else {
            return false;
          }
        });
      }
    },
    // 搜索标签
    supportsearch() {
      let searchtext = this.$refs.supportsearchtext.value;
      this.note_name = searchtext;
	  this.currentPage = 1;
      this.supportlistapi();
    },
    handleSizeChange(val) {
      this.eachPage = val;
      this.supportlistapi();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.common.backTop();
      this.supportlistapi();
    },
  },
};
</script>
<style scoped>
.classifyTable /deep/ .el-table th:first-child > .cell{padding-left: 14px;}
.addsingle{
	padding-bottom: 20px;
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: inset 0 -1px 0 #efefef;
}
.goods-label {
	color: #666;
}

.el-radio__input.is-checked .el-radio__inner {
  background-color: #fff !important;
  width: 18px;
  height: 18px;
}


.el-radio__input .el-radio__inner {
  width: 18px;
  height: 18px;
}

.el-radio__input.is-checked .el-radio__inner::after {
  width: 8px;
  height: 8px;
  background-color: var(--fontColor);
}

.box_right {
  width: calc(100% - 106px);
  height: 100vh;
  margin-left: 106px;
  box-sizing: border-box;
  /* position: relative; */
  display: flex;
  min-height: 0;
  flex: 1 1 auto;
}

.goods_left {
  width: 134px;
  flex: 0 0 134px;
  background: #fff;
  height: 100vh;
  z-index: 10;
  border-right: 1px solid #eee;
  position: fixed;
  top: 0;
  left: 106px;
  box-sizing: border-box;
  /* overflow: hidden; */
  margin-top: 50px;
}

.goods_headline {
  height: 50px;
  line-height: 50px;
  padding-left: 23px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #eee;
  /* margin-bottom: 10px; */
  padding-top: 0;
}

.class_nav {
  background-color: #fff;
}

.nav_main {
  padding: 20px;
}


.addtable_headed {
  padding-left: 0;
  margin-bottom: 0 !important;
}

.table_head .el-select {
  width: 130px;
  margin-bottom: 20px;
  float: left;
}
.table_head /deep/ .el-select .el-input{width: 100%;}

.table_head .el-select.is-focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.table_head .el-select:focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.table_head .el-select:hover .el-input__inner {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.table_search {
  float: right;
}

.table_search > span {
  padding: 0 18px;
  font-size: 14px;
}

.classifyTable table th,
table td {
  border: none;
}

.classifyTable
  .el-table
  .el-table__header-wrapper
  .el-table__header
  thead
  tr
  th {
  background-color: #f9f9fc;
}

.classifyTable .el-table .el-table__header-wrapper {
  background-color: #f9f9fc;
}

.classifyTable .el-table .el-table__fixed .el-table__fixed-header-wrapper {
  background-color: #f9f9fc;
}

.el-table th.el-table__cell {
  background-color: #f9f9fc;
  font-weight: 600;
  color: #333;
  padding: 8px 0;
}

.el-table th:first-child.el-table__cell > .cell {
  padding-left: 13px;
}

.caozuo a {
  padding: 0 10px;
  border-right: 1px solid var(--fontColor, #fb6638);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: var(--fontColor);
}

.caozuo a:last-child {
  border-right: none;
}

.table_memoed {
  width: 100%;
  text-align: center;
}

.table_memoed > .cell {
  text-align: center;
}

.table_memoed p {
  background-color: #f0ad4e;
  padding: 3px 3px;
  display: inline-block !important;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  cursor: pointer;
}

.table_memoed > .active {
  background-color: #5cb85c;
}

.empty_data {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.empty_data p {
  margin-top: 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}

.table_foot {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_pages {
  display: flex;
  align-items: center;
}

.main_pages .el-pagination {
  display: flex;
  align-items: center;
}

.main_pages .el-pagination .el-pager {
  display: flex;
  align-items: center;
}

.main_pages .el-pagination .el-pager .number {
  min-width: 32px;
  height: 32px !important;
  line-height: 32px !important;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dcdee0;
  margin-left: 4px;
}

.main_pages .el-pagination .el-pager .number.active {
  color: #fff !important;
  background: var(--fontColor);
  border-color: var(--fontColor, #fb6638) !important;
}

.main_pages .el-pagination .el-pager .number:first-child {
  margin-left: 0;
}

.add_tag {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.tagTips {
  color: #636c72;
  font-size: 14px;
  width: 90%;
  line-height: 1.5;
}

.batch_wrap {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.batch_title {
  line-height: 44px;
  margin-right: 20px;
}

.biaoqian {
  width: 90%;
}
.biaoqian .el-input{width: 100%;}

.batch_url .goodsbtned {
  border-radius: 0 4px 4px 0;
}

.batch_url > .el-input > .el-input-group__append {
  padding: 0;
}

.bath_tips {
  color: #d9534f;
  margin: 10px 0;
}
</style>
